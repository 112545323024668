
import { Component, Vue } from "vue-property-decorator";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import Newsletter from "@/components/common/Newsletter.vue";
import Content from "@/components/common/Content.vue";
import { HcaptchaElement } from "@/interfaces/misc";
import i18n from "@/i18n";
const pageKey = "signin-therapist";

@Component({
  components: { Content, VueHcaptcha, Newsletter },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class TherapistSignIn extends Vue {
  username = "";
  password = "";
  sitekey = process.env.VUE_APP_SITE_KEY;

  get user() {
    return this.$store.state.user;
  }

  async login(captcha_token: string) {
    try {
      if (this.username && this.password) {
        await this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
          captcha_token: captcha_token,
        });
        if (!this.$store.getters.isLoggedIn)
          return alert(this.$t("login.error"));

        const redirect = this.$route.query.redirect;

        if (redirect) this.$router.push(String(redirect));
        else this.$router.push("dashboard");
      } else this.$toasted.show(this.$t("signup.fill-all") as string);
    } catch (err) {
      if (err.request.status === 401)
        this.$toasted.show(this.$t("signup.wrong-credentials") as string);
      else this.$toasted.show(this.$t("signup.server-error") as string);
    }
  }

  validate() {
    (this.$refs.hcaptcha as HcaptchaElement).execute();
  }

  onCaptchaExpired() {
    (this.$refs.hcaptcha as HcaptchaElement).reset();
  }
}
